import React from 'react'
import { Link } from 'gatsby'

import Header from '../components/Header'

const navStyle = {
  display: 'flex',
  justifyContent: 'center',
  padding: '15px 8px',
}

const IndexPage = ({ data }) => {
  return (
    <>
      <Header />
      <div style={navStyle}>
        <Link to="/cats/gus1" className={'next-link'}>
          <button>Cats Slideshow</button>
        </Link>
      </div>
    </>
  )
}

export default IndexPage
